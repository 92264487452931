.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  height: 75vh;
}

.title {
  font-size: 28px ;
}

.url {
  color: #1a71ad;
  cursor: pointer;
}
