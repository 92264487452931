.row {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 1200px) {
  .row {
    gap: 100px;
  }

  .row:last-child {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 786px) {
  .container {
    width: 100%;
  }
}

@media screen and (max-width: 666px) {
  .row {
    max-width: 396px;
    flex-direction: column;
    gap: 0;
  }
}
