.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin: 25px 0;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.input {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  max-width: 360px;
  width: 100%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 47px;
  background: #221F20;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: 0.7s ease-in-out;
}

.button:hover {
  transition: 0.7s ease-in-out;
  background-color: #dadcde;
  color: black;
}

.input:focus {
  border-color: wheat;
}

.error {
  margin-left: 5px;
  margin-bottom: 3px;
  color: red;
  font-size: 14px;
}

.warning {
  font-weight: bold;
  margin-left: 5px;
  margin-bottom: 3px;
  color: #1a71ad;
  font-size: 14px;
}
