.footer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 8vh;
  width: 100%;
  min-width: 320px;
  min-height: 60px;
  background-color: whitesmoke;
  opacity: 0.8;
  padding: 0 35px;
  gap: 10px;
  z-index: 1000;

  a {
    height: 24px ;
  }

  a:visited {
    color: black;
  }

  a:active {
    color: black;
  }

  a:link  {
    color: black;
  }

  &Disable {
    display: none;
  }
}

.privacy {
  text-decoration: none;
  color: black;
}

.privacy:hover {
  color: #1a71ad;
}

@media screen and (max-width: 1200px) {
  .footer {
   display: none;
  }
}
