.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 74vh;
}

.wineImg {
  width: 350px;
  height: 500px;
  border-radius: 17px;
}
