.logo {
  width: 150px;
  animation: rotate 3s infinite;
  fill: white;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 999;
  background: #000;
  -webkit-transition: opacity .7s ease .7s,-webkit-transform 0s linear 1.4s;
  transition: opacity .7s ease .7s,-webkit-transform 0s linear 1.4s;
  transition: opacity .7s ease .7s,transform 0s linear 1.4s;
  transition: opacity .7s ease .7s,transform 0s linear 1.4s,-webkit-transform 0s linear 1.4s;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: radial-gradient(#767474, #3b3836);
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
