.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.left {
  width: 100%;
}

.description {
  letter-spacing: 1.04px;
  background-color: transparent;
  color: whitesmoke;
  //width: 350px;
  width: 60%;
  //padding: 0 25px;
  padding-left: 25px;
  box-sizing: border-box;
}

.info {
  font-size: 16px;
  line-height: 1.4;
  overflow: auto;
  //height: 400px;
}

.info::-webkit-scrollbar {
  width: 2px;
  cursor: pointer;
}

.info::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
  height: 10px;
}

/* Handle */
.info::-webkit-scrollbar-thumb {
  background: whitesmoke;
  border-radius: 10px;
  cursor: pointer;
  height: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}

@media screen and (min-width: 1200px) {
  .description {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    margin-left: 25px;
  }
}

@media screen and (max-width: 1200px) {
  .container {
  }

  .description {
    width: 100%;
    color: black;
    text-align: center;
    padding: 0 25px;

  }

  .info {
    height: 176px;
  }
}

