.container {
  height: 75vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.background {
  position: absolute;
  right: 0;
  top: 0;

  img {
    width: 250px;
    height: 300px;
  }
}

.left{
  width: 35%;
  display: flex;
  justify-content: flex-end;
  background-image: linear-gradient(to bottom, white, rgb(149 165 124) );
}

.right {
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: linear-gradient(to right, white, rgb(149 165 124) );
  height: 75vh;
}

.title {
  margin-bottom: 0;
}

.description {
  border: 1px solid  rgb(98,152,152);
  border-top: none;
  background-color: #ffffff;
  width: 350px;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 15px;
  margin: 0;
}

.info {
  font-size: 16px;
  line-height: 1.4;
  overflow: auto;
  height: 400px;
}
.info::-webkit-scrollbar {
  width: 2px;
  cursor: pointer;
}

.info::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
  height: 10px;
}

/* Handle */
.info::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
  cursor: pointer;
  height: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}

@media screen and (max-width: 1200px) {
  .container {
    height: 100vh;
  }

  .left {
    width: 100%;
    justify-content: center;
    background-image: linear-gradient(to right, white, rgb(149 165 124) );
  }

  .right {
    width: 100%;
    height: unset;
    justify-content: center;
  }

  .description {
    margin-top: 0;
    border: none;
    width: 100%;
    height: 100%;
  }

  .info {
    border-bottom: none;
    max-height: 500px;
  }
}
