.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: url('https://images.pexels.com/photos/2339180/pexels-photo-2339180.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1') no-repeat center center fixed;
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.sections {
  margin-top: -30%;
  user-select: none ;
  flex-wrap: wrap;
  font-size: 30px;
  font-weight: 800;
  color: wheat;

  a {
    text-decoration: none;
  }
}

.text {
  color: wheat;
  text-decoration: none;
}

.text:hover {
  transition: 0.7s;
  color: black;
}

.icon {
  position: absolute;
  right: 25px;
  top: 25px;
  font-size: 2rem !important;
  color: wheat;
}

.mobileFooter {
  position: fixed;
  bottom: 0;
  color: wheat;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 8vh;
  width: 100%;
  min-height: 60px;
  background-color: transparent;
  padding: 0 35px;
  gap: 10px;
  z-index: 1000;

  a {
    height: 24px ;
  }

  a:visited {
    color: wheat;
  }

  a:active {
    color: wheat;
  }

  a:link  {
    color: wheat;
  }

}


.privacy {
  text-decoration: none;
  color: wheat;
}
