.container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
}

.hidden {
  height: 0;
}

.navLink {
  text-decoration: none;
}

.after {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.1);
  bottom: 0;
  z-index: 9;
}

.video {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  object-fit: cover;
}

.sections {
  background-color: black;
  width: 100%;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  height: 10vh;
  position: absolute;
  z-index: 11;
  opacity: 0.3;
  transition: opacity 1s;
  left: 0;
  top: 0;
  gap: 50px;
  display: flex;

  &Hideen {
    background-color: transparent;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 15px;
}

.languages {
  color: white;
  margin-left: 5px;
  border-left: 1px solid white;
  padding-left: 10px;

  span {
    cursor: pointer;
  }

  span:hover {
    background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 25.5%, transparent 40.25%);
  }
}

.icon {
  color: white;
  font-size: 3.5rem !important;
}

.text {
  font-size: 24px;
  color: whitesmoke;
}

.sections:hover {
  transition: opacity 1s;
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .sections {
    flex-direction: column;
    width: 150px;
    justify-content: center;
    align-items: center;
    height: 100vh;
    left: 0;
    top: 0;
    flex-direction: column;
    opacity: 0.7;
  }

  .wrapper {
    top: 0;
  }

  .languages {
    display: flex;
    color: whitesmoke;
    top: 10px;
    left: unset;
  }
}
