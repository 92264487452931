.container {
  height: 75vh;
  background-image: linear-gradient(to left, white, #b4afaf);
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.title {
  margin: 0;
}

.left {
  width: 50%;
}

.right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  max-height: 300px;
}

.info {
  font-size: 16px;
  line-height: 1.4;
  overflow: auto;
  max-width: 680px;
  max-height: 200px;
}

.info::-webkit-scrollbar {
  width: 2px;
  cursor: pointer;
}

.info::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  cursor: pointer;
  height: 10px;
}

/* Handle */
.info::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
  cursor: pointer;
  height: 10px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}

@media screen and (max-width: 1200px) {
  .container {
    height: 100%;
    flex-direction: column-reverse;
  }

  .left {
    width: 100%;
    height: 100%;
  }

  .right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .info {
    max-height: unset;
  }

  .description {
    margin-bottom: 60px;
    max-width: 700px;
    height: 100%;
    max-height: unset;
    padding: 0;
  }
}

@media screen and (max-width: 786px) {
  .left {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 666px) {
  .container {
    background-image: none;
    border-top: 1px solid rgba(161, 157, 157, 0.42);
  }

  .description {
    padding: 0 20px;
  }
}

