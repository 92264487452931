.flipCard {
  margin-top: 35px;
  background-color: transparent;
  width: 180px;
  max-width: 180px;
  height: 220px;
  perspective: 1000px;
  border-radius: 17px;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 17px;
}

.flipCard:hover .flipCardInner {
  transform: rotateY(180deg);
}

.flipCardFront, .flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 17px;
}

.flipCardFront {
  background-color: #bbb;
  color: black;
}

.flipCardBack {
  background-color: black;
  color: white;
  transform: rotateY(180deg);
}

.flipImg {
  width: 180px;
  max-width: 180px;
  height: 220px;
  border-radius: 17px;
}

.type {
  margin: 0;
  background-color: #221F20;
  color: whitesmoke;
  text-align: center;
  border-radius: 17px;
}

@media screen and (max-width: 786px) {
  .flipImg {
    width: 300px;
    max-width: 300px;
    height: 300px;
    object-fit: cover;
  }

  .flipCard:hover .flipCardInner {
    transform: rotateY(0deg);
  }

  .flipCard {
    width: 300px;
    max-width: 300px;
    height: 300px;
  }
}
