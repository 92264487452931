.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 17vh;
  width: 100%;
  min-width: 320px;
  min-height: 90px;
  background-color: whitesmoke;
  padding: 0 35px;
  z-index: 900;

  &Disable {
    display: none;
  }
}
.shake {
  width: 47px;
  height: 47px;
}

.shake:hover {
  animation: tilt-shaking 0.25s infinite;
}

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.leftPart {
  display: flex;
}

.logo {
  user-select: none;
  width: 94px;
}

.navLink {
  text-decoration: none;
  text-align: center;
  color: #000000;

  p {
    margin: 0;
  }
}

.sections {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  font-size: 16px;
  color: #221F20;
  margin-left: 100px;
}

.icon {
  font-size: 3.5rem !important;
  border-right: 2px solid grey;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    text-decoration: none;
  }
}

.btnWrapper {
  border-right: 2px solid grey;
  padding: 5px;
  transition: opacity 0.6s;
}

.btnWrapper:hover {
  transition: opacity 0.6s;
  opacity: 0.5;
}

.shopBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 47px;
  background: #221F20;
  border-radius: 100px;
  color: white;
  cursor: pointer;
}

.languages {
  display: grid;
  margin-left: 5px;

  span {
    cursor: pointer;
  }

  span:hover {
    background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 25.5%, transparent 40.25%);
  }
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 0 10px;
  }
}
