//.row {
//  display: flex;
//  gap: 20px;
//}
//
//@media screen and (max-width: 1200px) {
//  .row {
//    gap: 100px;
//  }
//
//  .row:last-child {
//    margin-bottom: 45px;
//  }
//}
//
//@media screen and (max-width: 786px) {
//  .container {
//    width: 100%;
//  }
//}
//
//@media screen and (max-width: 666px) {
//  .row {
//    max-width: 396px;
//    flex-direction: column;
//    gap: 0;
//  }
//}
//

.container {
  display: flex;
  width: 100%;
  //background-image: linear-gradient(to right, #DECBA4, #3E5151);
}

.carouselBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  //background-image: linear-gradient(to right, #DECBA4, #3E5151);
  padding-bottom: 35px;
}

.parent {
  text-align: center;
  width: 50%;
  padding-bottom: 35px;

  h1 {
    //@supports (background-clip: text) or (-webkit-background-clip: text) {
    //  //background-image: linear-gradient(to right, #d9d4d4, #171414, #ffffff, #030303);
    //  -webkit-text-stroke-color: transparent;
    //  -webkit-text-stroke-width: var(--stroke-width);;
    //  background-size: 110% auto;
    //  background-position: center;
    //  color: whitesmoke;
    //  -webkit-background-clip: text;
    //  background-clip: text;
    //}
  }
}

.left {
  border-right: 1px solid grey;

  //display: flex;
  //justify-content: space-around;
  //align-items: center;
  //flex-wrap: wrap;
}
//
.right {

//  display: flex;
//  justify-content: space-around;
//  align-items: center;
//  flex-wrap: wrap;
}

.flexity {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
