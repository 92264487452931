.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  background-color: #adaabd;

  a {
    text-decoration: none;
    color: black;
  }
}

.logo {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 125px;
  object-fit: cover;
  background-color: white;
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.banner {
  position: relative;
  height: 280px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
}

.banner img {
  object-fit: cover;
}

.socialWrapper {
  margin-top: 80px;
}

.socialWrapper > div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  padding: 15px;
  background-color: #f7f6f95e;
  border-radius: 30px;
  margin-bottom: 15px;
}
.socialWrapper > div:last-child {
  padding-bottom: 15px;
  }

.socialWrapper img {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 15px;
}

.title {
  font-size: 28px ;
}

.url {
  color: #1a71ad;
  cursor: pointer;
}
