.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 60px;
}

.row {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 1200px) {
  .row {
    gap: 100px;
  }
  .row:last-child {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 786px) {
  .container {
    gap: 25px;
  }

  .row {
    gap: 25px;
    flex-direction: column;
  }
}
