.img {
  max-width: 310px;
  max-height: 281px;
  object-fit: cover;
}

.card {
  text-align: center;
  display: flex;
  justify-content: center;

  a{
    background-color: #000;
  }
}

.descriptionBlock {
  padding: 0 15px 5px;
  max-width: 360px;
  //max-height: 250px;
  background-color: whitesmoke;
}

.title {
  margin-top: 5px;
  margin-bottom: 5px;
  color: orangered;
}

.price {
  margin: 0px;
  color: #000;
  font-size: 22px;
}

.description {
  margin: 0;
}

.btn {
  outline: 0;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  font-size: 18px;
}

.btn:hover {
  opacity: 0.7;
}

@media screen and (max-width: 666px) {
  .card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 5px;
  }

  .img {
    max-width: 396px;
  }

  .descriptionBlock  {
    max-width: 396px;
    padding: 0;
  }

  .btn {
    max-width: 396px;
    width: 100%;
  }
}
